<template>
  <div class="dashboard__container--body" v-if="(userProfile  && userProfile.id)">
    <div class="dashboard__container--body--col shiftCard">
      <div class="pa-5">

      <div>
        <h3 class="mb-2">Required Sexual Harassment Training</h3>
        <p class="mb-2">You are legally required to complete the online training, pass the quiz, and then upload your completion certificate. Please allow approximately 2 hours to complete the course. Note: Choose "nonsupervisory" when you choose your training type.</p>
        <a href="https://calcivilrights.ca.gov/shpt/" target="_blank">
        <button class="btn btn__primary mr-3 mb-3">Start the Training</button>
        </a>
        <a href="https://firebasestorage.googleapis.com/v0/b/mvpes-25aef.appspot.com/o/Workplace-Discrimination-Poster_ENG.pdf?alt=media&token=15cdc874-b090-46c5-a0cb-a965cda4d56b" target="_blank">
        <button class="btn btn__primary mb-3">Sexual Harassment Poster</button>
        </a>
      </div>
      <hr>

      <h2>About The Civil Rights Department Training</h2>
    
    <p>The Civil Rights Department provides free online training courses on preventing sexual harassment and abusive conduct in the workplace that satisfy California’s legal training requirements pursuant to Government Code section 12950.1.</p>
    
    <p>California law requires all employers of 5 or more employees to provide training to its supervisory and nonsupervisory employees on sexual harassment and abusive conduct prevention. Every two years, nonsupervisory employees must receive at least one hour of training and supervisory employees must receive at least two hours of training. The training may be completed all at once or in segments, as long as the applicable hourly total requirement is met. The law requires the training to include practical examples of harassment based on gender identity, gender expression, and sexual orientation.</p>
    
    <h2>For additional information:</h2>
    <ul>
        <li><a href="https://calcivilrights.ca.gov/shptfaq-employee/" target="_blank">Frequently Asked Questions</a></li>
    </ul>
    
    <h2>Technology</h2>
    <p>The training is accessible on a computer or mobile device.</p>
    <p>Internet browsers required:</p>
    <ul>
        <li>Windows: Microsoft Edge (latest version), Google Chrome (latest version), Firefox (latest version)</li>
        <li>Mac: Safari (latest version), Google Chrome (latest version), Firefox (latest version)</li>
        <li>Mobile: Safari in Apple iOS/iPadOS (latest version), Google Chrome (latest version) in Apple iOS/iPadOS (latest version), Google Chrome (latest version) in Android OS 6 or later</li>
    </ul>
    <p>The training module will not save your progress. If you refresh or reload the page, you may lose your progress toward completion.</p>
    <p>At the end of the training, you will have the option to utilize a certificate of completion. Using computer/mobile device functions, you can choose to save, print, take a screenshot, or take a photo of the certificate. Please consult your employer for direction on if/how they prefer to receive the certificate. CRD cannot email you a replacement certificate.</p>
    
    <h2>Limitations on Use</h2>
    <p>CRD's trainings may not be duplicated, reproduced, distributed, or ingested into a third-party system such as employers' e-learning platforms. The trainings contain materials licensed only to CRD. CRD anticipates updating these trainings from time to time to account for legal and policy developments.</p>
    
    <h2>Accessibility</h2>
    <p>The trainings are available in English, Spanish, Korean, Chinese, Vietnamese, and Tagalog.</p>
    <p>You may choose to turn closed captioning on or off in any version.</p>
    <p>There is alt-text for the images and the icons.</p>
</div>
    </div>
    <div class="dashboard__container--body--col">
      <div class="mt-3" >
        <label for="fileTitle">Upload Your Completion Certificate:</label>
        <div class="mt-2">
        <input type="file" ref="fileInputTip" id="certUpload" accept="image/*,application/pdf,.doc" @change="previewImage" hidden>
        <button @click="chooseFile()" class="btn btn__primary mb-2">Choose File</button>
        </div>

        <!-- <input type="file" ref="fileInputTip" accept="image/*,application/pdf,.doc" @change="previewImage"> -->
        <progress :value="uploadValue" max="100" v-if="showBar"></progress>
       <!--  <div class="mb-3">
          <button v-if="imageData != null && type" class="btn btn__primary mt-3" @click="onUploadFile">
            Upload
            <transition name="fade">
              <span class="ml-2" v-if="performingRequest">
              <i class="fa fa-spinner fa-spin"></i>
              </span>
            </transition>
          </button>
        </div> -->
      </div>
      <hr>
      <div class="mt-3">
        <vue-good-table
          :columns="columns"
          :rows="userProfile.harassmentCerts"
          >
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'url'">
              <a :href="props.row.url" target="_blank">View <i class="fas fa-external-link"></i></a>
            </span>
            <span v-else-if="props.column.field == 'type'">
              Sexual Harassment Training Certification
            </span>
            <!-- <span v-else>
              {{props.formattedRow[props.column.field]}}
            </span> -->
          </template>
        </vue-good-table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Loader from '@/components/Loader.vue'
const fb = require('../../firebaseConfig.js')
// import ProfileCertifications from '@/components/Profile/ProfileCertifications.vue'

export default {
  name: 'sexualHarassment',
  data: () => ({ 
    imageData: null,
    uploadValue: 0,
    showBar:false,
    performingRequest: false,
    columns: [
      {
        label: 'Type',
        field: 'type',
        sortable: false
      },
      {
        label: 'Url',
        field: 'url',
        sortable: false
      },
    ]
  }),
  computed: {
    ...mapState(['userProfile', 'currentUser']),
  },
   components: {
    Loader,
  },
  methods: {
    chooseFile() {
      document.getElementById("certUpload").click()
    },
    previewImage(event) {
      console.log(event)
      this.uploadValue=0;
      this.imageData=event.target.files[0]
      this.onUploadFile()
    },
    onUploadFile() {
      console.log('onUpload')
      this.showBar = true
      let userProfile = this.userProfile
      let rand = (Math.random().toString(36).substring(2, 16) + Math.random().toString(36).substring(2, 16)).toUpperCase()
      let storageRef = fb.storageRef.child('docs/' + rand).put(this.imageData);
      storageRef.on(`state_changed`, snapshot => {
        this.uploadValue=(snapshot.bytesTransferred/snapshot.totalBytes)*100;
      }, error => {console.log(error.message)},
      () => {this.uploadValue=100;
        storageRef.snapshot.ref.getDownloadURL().then((downloadURL) => {
          console.log('File available at', downloadURL)
          var docRef = fb.usersCollection.doc(userProfile.id)
          docRef.update({

          harassmentCerts: fb.firestore.FieldValue.arrayUnion({
            url: downloadURL,
          })
        })
        this.showBar = false
      })
      this.imageData = null
      this.$refs.fileInputTip.value=null
    })
  },
  onFileTypeMismatch(file) {
      alert('Invalid file type. Please choose a jpeg or png file.')
    },
    onFileSizeExceed(file) {
      alert('Please choose a file smaller than 2MB')
    },
  }
}
</script>